export const country_pages = [
{
    country_name: 'Australia',
    country_code: 'AU',
    endpoint: '/australia'
  },
  {
    country_name: 'Austria',
    country_code: 'AT',
    endpoint: '/austria'
  },
  {
    country_name: 'Belgium',
    country_code: 'BE',
    endpoint: '/belgium'
  },
  {
    country_name: 'Benin',
    country_code: 'BJ',
    endpoint: '/benin'
  },
  {
    country_name: 'Brazil',
    country_code: 'BR',
    endpoint: '/brazil'
  },
  {
    country_name: 'Bulgaria',
    country_code: 'BG',
    endpoint: '/bulgaria'
  },
  {
    country_name: 'Canada',
    country_code: 'CA',
    endpoint: '/canada'
  },
  {
    country_name: 'Croatia',
    country_code: 'HR',
    endpoint: '/croatia'
  },
  {
    country_name: 'Cyprus',
    country_code: 'CY',
    endpoint: '/cyprus'
  },
  {
    country_name: 'Czech Republic',
    country_code: 'CZ',
    endpoint: '/czechrepublic'
  },
  {
    country_name: 'Denmark',
    country_code: 'DK',
    endpoint: '/denmark'
  },
  {
    country_name: 'Egypt',
    country_code: 'eg',
    endpoint: '/egypt'
  },
  {
    country_name: 'Estonia',
    country_code: 'EE',
    endpoint: '/estonia'
  },
  {
    country_name: 'Finland',
    country_code: 'FI',
    endpoint: '/finland'
  },
  {
    country_name: 'France',
    country_code: 'FR',
    endpoint: '/france'
  },
  {
    country_name: 'Germany',
    country_code: 'DE',
    endpoint: '/germany'
  },
  {
    country_name: 'Ghana',
    country_code: 'GH',
    endpoint: '/ghana'
  },
  {
    country_name: 'Gibraltar',
    country_code: 'GI',
    endpoint: '/gibraltar'
  },
  {
    country_name: 'Greece',
    country_code: 'GR',
    endpoint: '/greece'
  },
  {
    country_name: 'Hong Kong',
    country_code: 'HK',
    endpoint: '/hongkong'
  },
  {
    country_name: 'Hungary',
    country_code: 'HU',
    endpoint: '/hungary'
  },

  {
    country_name: 'Indonesia',
    country_code: 'ID',
    endpoint: '/indonesia'
  },
  {
    country_name: 'Ireland',
    country_code: 'IE',
    endpoint: '/ireland'
  },
  {
    country_name: 'Italy',
    country_code: 'IT',
    endpoint: '/italy'
  },
  {
    country_name: 'Japan',
    country_code: 'JP',
    endpoint: '/japan'
  },
  {
    country_name: 'Jordan',
    country_code: 'jo',
    endpoint: '/jordan'
  },
  {
    country_name: 'Kenya',
    country_code: 'KE',
    endpoint: '/kenya'
  },
  {
    country_name: 'Kuwait',
    country_code: 'kw',
    endpoint: '/kuwait'
  },
  {
    country_name: 'Latvia',
    country_code: 'LV',
    endpoint: '/latvia'
  },
  {
    country_name: 'Lebanon',
    country_code: 'lb',
    endpoint: '/lebanon'
  },
  {
    country_name: 'Liechtenstein',
    country_code: 'LI',
    endpoint: '/liechtenstein'
  },
  {
    country_name: 'Lithuania',
    country_code: 'LT',
    endpoint: '/lithuania'
  },
  {
    country_name: 'Luxembourg',
    country_code: 'LU',
    endpoint: '/luxembourg'
  },
  {
    country_name: 'Malaysia',
    country_code: 'MY',
    endpoint: '/malaysia'
  },
  {
    country_name: 'Malta',
    country_code: 'MT',
    endpoint: '/malta'
  },
  {
    country_name: 'Mexico',
    country_code: 'MX',
    endpoint: '/mexico'
  },
  {
    country_name: 'Netherlands',
    country_code: 'NL',
    endpoint: '/netherlands'
  },
  {
    country_name: 'New Zealand',
    country_code: 'NZ',
    endpoint: '/newzealand'
  },
  {
    country_name: 'Nigeria',
    country_code: 'NG',
    endpoint: '/nigeria'
  },
  {
    country_name: 'Norway',
    country_code: 'NO',
    endpoint: '/norway'
  },
  {
    country_name: 'Oman',
    country_code: 'om',
    endpoint: '/oman'
  },

  {
    country_name: 'Portugal',
    country_code: 'PT',
    endpoint: '/portugal'
  },
  {
    country_name: 'Qatar',
    country_code: 'qa',
    endpoint: '/qatar'
  },
  {
    country_name: 'Romania',
    country_code: 'RO',
    endpoint: '/romania'
  },
  {
    country_name: 'Saudi Arabia(KSA)',
    country_code: 'SA',
    endpoint: '/sa'
  },
  {
    country_name: 'Senegal',
    country_code: 'SN',
    endpoint: '/senegal'
  },
  {
    country_name: 'Singapore',
    country_code: 'SG',
    endpoint: '/singapore'
  },
  {
    country_name: 'Slovakia',
    country_code: 'SK',
    endpoint: '/slovakia'
  },
  {
    country_name: 'Slovenia',
    country_code: 'SI',
    endpoint: '/slovenia'
  },
  {
    country_name: 'South Africa',
    country_code: 'ZA',
    endpoint: '/southafrica'
  },
  {
    country_name: 'Spain',
    country_code: 'ES',
    endpoint: '/spain'
  },
  {
    country_name: 'Sweden',
    country_code: 'SE',
    endpoint: '/sweden'
  },
  {
    country_name: 'Switzerland',
    country_code: 'CH',
    endpoint: '/switzerland'
  },
  {
    country_name: 'Thailand',
    country_code: 'TH',
    endpoint: '/thailand'
  },
  {
    country_name: 'United Arab Emirates',
    country_code: 'AE',
    endpoint: '/ae'
  },
  {
    country_name: 'United Kingdom',
    country_code: 'GB',
    endpoint: '/united-kingdom'
  },
  {
    country_name: 'United States',
    country_code: 'US',
    endpoint: '/unitedstates'
  },
]
